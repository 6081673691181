:root {
  --main-color: #344cb7;
  --main-alt-color: #2fa4ff;
  --secondary-color: #577bc1;
  --common-color: #eeeeee;
  --light-color: white;
  --gray-color: #777;
  --low-transition: 0.4s;
}

/* Start Global Rules */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
  overflow-x: hidden;
}

body {
  font-family: sans-serif;
  width: 100vw;
  background-color: var(--common-color);
  overflow-x: hidden;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

/* End Global Rules */
/* Start Responsive Containers */

/* Small Screens */
@media (min-width: 767px) {
  .container {
    width: 750px;
  }
}

/* Medium Screens */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

/* Large Screens */
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

/* End Responsive Containers */
/* Start Components */

.light-overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.45);
}

.fit-overlay {
  width: fit-content;
  background-color: rgb(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 30px;
}

.deep-overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.85);
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 2px 10px;
}

.section-heading {
  text-align: center;
  padding: 20px;
}

.section-heading h2 {
  font-size: 35px;
  padding: 15px;
}

.section-heading p {
  font-size: 17px;
  padding: 5px;
  color: var(--gray-color);
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-link {
  margin: 10px;
  padding: 12px;
  background-color: #eeeeee;
  transition: var(--low-transition);
  color: var(--main-alt-color);
  border-radius: 100%;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.social-link:hover {
  color: var(--light-color);
  background-color: var(--main-alt-color);
  transform: scale(1.1) translateY(-5px);
}

.social-link img {
  width: 25px;
  height: 25px;
  transition: var(--low-transition);
}

.social-link:hover img {
  filter: invert(1);
}

/* End Components */
/* Start Landing.jsx */

.landing {
  background: url(/public/images/home-bg.jpg) 50% 0 repeat-y fixed;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  text-align: right;
}

.landing h1 {
  font-size: 70px;
  color: var(--light-color);
  padding: 20px;
  letter-spacing: -1.5px;
}

.landing h4 {
  font-size: 30px;
  color: var(--light-color);
  padding: 20px;
  letter-spacing: -1.5px;
}

.landing button {
  font-size: 23px;
  color: var(--light-color);
  padding: 10px 20px;
  border-radius: 9999px;
  border: 0;
  background-color: transparent;
  border: 3px solid var(--light-color);
  transition: var(--low-transition);
}

.landing button:hover {
  background-color: var(--light-color);
  color: black;
}

.fix-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 992px) {
  .landing {
    text-align: center;
  }

  .fix-overlay {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .landing h1 {
    font-size: 50px;
  }
  .landing h4 {
    font-size: 23px;
  }
}

/* End Landing.jsx */
/* Start Skills.jsx */

.skills-area {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  text-align: center;
  gap: 20px;
}

/* End Skills.jsx */
/* Start Skill.jsx */

.skill {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
}

.skill img {
  width: 50%;
  height: 50%;
  transition: 0.3s;
}

.skill:hover img {
  filter: invert(1);
}

.skill .icon {
  color: var(--main-alt-color);
  padding: 30px;
  position: relative;
  top: 0;
  font-size: 90px;
  transition: all ease-in-out 0.4s;
}

.skill:hover .icon {
  color: var(--light-color);
}

.skill h4 {
  font-size: 20px;
  font-weight: 900;
}

.skill:hover {
  color: var(--light-color);
  background-color: black;
  transform: scale(1.02) translateY(-5px);
}

@media (max-width: 767px) {
  .skill img {
    width: 25%;
    height: 25%;
  }
}

/* End Skill.jsx */
/* Start About.jsx */

.about {
  margin-top: 50px;
  background-color: white;
}

.about .container {
  padding: 25px 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.about .me {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.about .me .meImg {
  width: 350px;
  border-radius: 5px;
  margin-bottom: 22px;
  transition: border-radius 0.4s, box-shadow 1.75s;
  position: relative;
}

.about .me .meImg:hover {
  border-radius: 50%;
  box-shadow: 2px 2px 50px var(--main-alt-color);
}

.about .content {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.about .about-text {
  text-align: left;
}

.about .about-text p {
  padding: 5px;
  color: var(--gray-color);
  font-size: 15px;
}

@media (max-width: 992px) {
  .about .about-text {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .about .container {
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .about .me .meImg {
    width: 275px;
  }

  .about .content .section-heading h2 {
    font-size: 25px;
  }
}

/* End About.jsx */
/* Start Resume.jsx */

.resume-holder {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.resume {
  margin: 30px 0 0 0;
  outline: 0;
  border: 0;
  padding: 15px 80px;
  color: var(--light-color);
  background-color: var(--main-alt-color);
  font-size: 20px;
  border-radius: 9999px;
}

@media (max-width: 767px) {
  .resume {
    padding: 15px 60px;
    font-size: 15px;
  }
}

/* End Resume.jsx */
/* Start Projects.jsx */

.projects .projects-area {
  padding: 10px;
}

.projects .g3 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.projects .g2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 20px;
}

@media (max-width: 767px) {
  .projects .g3,
  .projects .g2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
}

/* End Projects.jsx */
/* Start Project.jsx */

.project {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.project::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: project-overlay 8s infinite;
}

.project .data-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--main-alt-color);
  transition: var(--low-transition);
  border-radius: 4px;
}

.project .data-overlay h4 {
  padding: 8px;
  color: var(--light-color);
  font-size: 18px;
}
.project .data-overlay h4.title {
  font-size: 27px;
}
.project .data-overlay h2 {
  padding: 4px;
  color: var(--light-color);
  font-size: 20px;
}

.project .data-overlay .buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 20%;
}

.project .data-overlay .buttons .githubLink button,
.project .data-overlay .buttons .websiteLink button {
  padding: 10px 20px;
  color: var(--common-color);
  background-color: transparent;
  border: 3px solid var(--common-color);
  font-size: 20px;
  font-weight: 700;
  border-radius: 9999px;
  outline: 0;
  transition: var(--low-transition);
}

.project .data-overlay .buttons .githubLink button:hover,
.project .data-overlay .buttons .websiteLink button:hover {
  background-color: var(--common-color);
  color: var(--main-alt-color);
}

.project:hover .data-overlay {
  opacity: 1;
}

.project .project-img {
  width: 100%;
  border-radius: 5px;
}

@keyframes project-overlay {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@media (max-width: 992px) {
  .project .data-overlay h4 {
    font-size: 22px;
  }
  .project .data-overlay h4.title {
    font-size: 35px;
  }
  .project .data-overlay h2 {
    font-size: 27px;
  }
}

@media (max-width: 767px) {
  .project {
    width: 90%;
  }
  .project .data-overlay {
    flex-wrap: nowrap;
    padding: 7px;
  }
  .project .data-overlay h4 {
    font-size: 11px;
  }
  .project .data-overlay h4.title {
    font-size: 16px;
  }
  .project .data-overlay h2 {
    font-size: 13px;
  }

  .project .data-overlay .buttons {
    display: block;
    padding: 0;
  }

  .project .data-overlay .buttons .githubLink button,
  .project .data-overlay .buttons .websiteLink button {
    padding: 4px 7px;
    font-size: 13px;
    font-weight: 400;
    margin-right: 3px;
  }
}

/* End Project.jsx */
/* Start Contact.jsx */

.contact {
  background-color: var(--light-color);
  margin-top: 50px;
  padding-bottom: 50px;
  padding-top: 20px;
}

.contact .container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75vw;
}

.contact .details ul {
  list-style: disc;
  list-style-position: inside;
}

.contact .details li {
  font-size: 15px;
  color: var(--gray-color);
  padding: 5px;
  transition: var(--low-transition);
}

.contact .details li:hover {
  color: black;
}

.contact .details li a {
  color: var(--gray-color);
  transition: var(--low-transition);
}

.contact .details li a:hover {
  color: black;
}

@media (max-width: 767px) {
  .contact .container {
    width: 90vw;
  }
}

/* End Contact.jsx */
/* Start Form.jsx */

#contact-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

#contact-form input,
#contact-form textarea {
  border: 0;
  border-bottom: 2px solid rgb(192, 192, 192);
  padding: 10px;
  outline: 0;
  font-size: 20px;
  transition: var(--low-transition);
}

#contact-form input:focus,
#contact-form textarea:focus {
  border-color: black;
}

#contact-form input[type="text"] {
  margin-right: 20px;
}

#contact-form input[type="text"],
#contact-form input[type="email"] {
  width: calc(50% - 20px);
  margin-bottom: 20px;
}

#contact-form textarea {
  resize: none;
  width: 100%;
}

#contact-form button {
  padding: 15px 25px;
  color: var(--light-color);
  background-color: black;
  margin-top: 20px;
  font-size: 20px;
  transition: var(--low-transition);
  border: 0;
  outline: 0;
  border-radius: 9999px;
}

#contact-form button:hover {
  background-color: var(--main-alt-color);
}

/* End Form.jsx */
/* Start Footer.jsx */

footer {
  background-color: var(--main-alt-color);
  color: var(--light-color);
  text-align: center;
  padding: 15px 25px;
}

footer h1 {
  font-size: 15px;
}

/* End Footer.jsx */
